// **  Initial State
const initialState = {
  Pathos_User: [],
  Pathos_Doctor: [],
  Pathos_Registrar: [],
  Patient_Orders: [],
  Pathelogys: [],
  err: null,
  loading: false
}

const PATHELOGYReducer = (state = initialState, action) => {
  if (action.type.includes("PATHELOGY") && action.type.includes("REQUEST")) return { ...state, loading: true }
  else if (action.type.includes("PATHELOGY") && action.type.includes("FAILED")) return { ...state, err: action.err, loading: false }
  else {
    switch (action.type) {
      case 'PATHOLOGY_SUCCESS_USER':
        return {
          ...state,
          Pathos_User: action.data,
          loading: false
        }
      // case 'PATHELOGY_SUCCESS_DOCTOR':
      //   return {
      //     ...state,
      //     Pathos_Doctor: action.data,
      //     loading: false
      //   }
      // case 'PATHELOGY_SUCCESS_REGISTRAR':
      //   return {
      //     ...state,
      //     Pathos_Registrar: action.data,
      //     loading: false
      //   }
      case 'PATHELOGY_SUCCESS_PATIENT':
        return {
          ...state,
          Patient_Orders: action.data,
          loading: false
        }
      case 'PATHELOGY_TYPES_SUCCESS':
        return {
          ...state,
          Pathelogys: action.data
        }
      case 'PATHELOGY_ORDER_ADD_SUCCESS':
        return {
          ...state,
          loading: false
        }
      case 'PATHELOGY_ORDER_DELETE_SUCCESS':
        return {
          ...state,
          loading: false
        }
      default:
        return { ...state }
    }
  }
}

export default PATHELOGYReducer
