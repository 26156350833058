const initialState = {
    err: null,
    loading: false,
    inserted: null,
    sms_code: null
}

const PatientRegisterReducer = (state = initialState, action) => {
    switch (action.type) {
        case "PATIENT_INSERT_REQUEST":
            return { ...state, loading: true }
        case "PATIENT_INSERT_SUCCESS":
            return { ...state, inserted: action.data, loading: false }
        case "PATIENT_INSERT_FAILED":
            return { ...state, err: action.err, loading: false }
        case "PIN_INSERT_REQUEST":
            return { ...state, loading: true }
        case "PIN_INSERT_SUCCESS":
            return { ...state, inserted: action.data, loading: false }
        case "PIN_INSERT_FAILED":
            return { ...state, err: action.err, loading: false }
        case "PIN_AUTOGEN_REQUEST":
            return { ...state, loading: true }
        case "PIN_AUTOGEN_SUCCESS":
            return { ...state, inserted: action.data, loading: false }
        case "PIN_AUTOGEN_FAILED":
            return { ...state, err: action.err, loading: false }
        case "PATIENT_CLEAR_REGISTER":
            return { ...state, inserted: null }
        case "PATIENT_PHONE_REQUEST":
            return { ...state, loading: true }
        case "PATIENT_PHONE_FAILED":
            return { ...state, err: action.err, loading: false}
        case "PATIENT_PHONE_SUCCESS":
            return { ...state, loading: false}
        case "PATIENT_SMS_REQUEST":
            return { ...state, loading: true }
        case "PATIENT_SMS_SUCCESS":
            return { ...state, sms_code: action.data.code, loading: false }
        case "PATIENT_SMS_FAILED":
            return { ...state, err: action.err, loading: false }

        default:
            return { ...state }

    }
}

export default PatientRegisterReducer