// **  Initial State
const initialState = {
    medical_specialities: [],
    surgical_specialities: [],
    specialities: [],
    err : null,
    speciality_group:0,
    loading: false,
    address_governments: [],
    address_cities: [],
    nationalities: [],
    organizations:[]
  }
  
  const RgTypesReducer = (state = initialState, action) => {
    if (action.type.includes("RGTYPES") && action.type.includes("REQUEST")) return {...state, loading: true}
    else if (action.type.includes("RGTYPES") && action.type.includes("FAILED")) return {...state, err: action.err, loading: false}
    else {
      switch (action.type) {
        case 'RGTYPES_SPECIALITY_SUCCESS':
                return {
                    ...state,
                    ...(action.group === 0) ? (
                    {
                      medical_specialities: action.data.map(row => {
                        return {value: row.id, label: row.name}
                      }),
                      speciality_group:0
                    }
                    ) : (
                    {
                      surgical_specialities: action.data.map(row => {
                        return {value: row.id, label: row.name}
                      }),
                      speciality_group:1
                    }
                    ),
                    loading: false
                  }
        case "RGTYPES_ALL_SPECIALITY_SUCCESS":
                  return {
                    ...state,
                    specialities: action.data
                  }
        case "RGTYPES_SPECIALITY_GROUP":
                  return {
                    ...state,
                    speciality_group: action.group
                  }
        case "RGTYPES_NATIONALITY_SUCCESS":
                  return {
                    ...state,
                    nationalities: action.data
                  }          
        case "RGTYPES_ADDRESS_GOVERNMENTS_SUCCESS":
                  return {
                    ...state,
                    loading: false,
                    address_governments: action.data
                  }
        case "RGTYPES_ORGANIZATION_SUCCESS":
                  return {
                      ...state,
                      loading: false,
                      organizations: action.data
                    }
        case "RGTYPES_ADDRESS_CITIES_SUCCESS":
                  return {
                    ...state,
                    loading: false,
                    address_cities: action.data
                  }                                           
        default:
          return {...state}
      }
    }
  }
  
  export default RgTypesReducer
  