const initialState = {
    loading: false,
    err: null,
    Prescriptions: [],
    Related: [],
    All_Prescriptions: [],
    Pharmicist_Data: []
}

const PharmcistReducer = (state = initialState, action) => {
    if (action.type.includes("UPHARMICIST") && action.type.includes("REQUEST")) return {...state, loading: true}
    else if (action.type.includes("UPHARMICIST") && action.type.includes("FAILED")) return {...state, err: action.err, loading: false}
    else {
        switch (action.type) {
            case "UPHARMICIST_GET_PATEINT_PRESCRIPTION_SUCCESS":
                return {...state, loading: false, Prescriptions: action.data}
            case "UPHARMICIST_GET_PATEINT_RELATED_PRESCRIPTION_SUCCESS":
                return {...state, loading: false, Related: action.data}
            case "UPHARMICIST_GET_PATEINT_ALL_PRESCRIPTION_SUCCESS":
                return {...state, loading: false, All_Prescriptions: action.data}
            case "UPHARMICIST_GET_PHARMICIST_PRESCRIPTION_SUCCESS":
                return {...state, loading: false, Pharmicist_Data: action.data}
            case "UPHARMICIST_PATEINT_DISPENSE_SUCCESS":
                return {...state, loading: false}
            case "UPHARMICIST_UPDATE_SUCCESS":
                return { ...state, loading: false }  
            case "UPHYSIOTHERAPIST_UPDATE_SUCCESS":
                return { ...state, loading: false }  
            case "UDENTIST_UPDATE_SUCCESS":
                return { ...state, loading: false }  
            default:
                return {...state}
        }
    }
}

export default PharmcistReducer