// **  Initial State
const initialState = {
  Radios_User: [],
  Radios_Doctor: [],
  Radios_Registrar: [],
  Patient_Orders: [],
  Radios: [],
  err: null,
  loading: false
}

const RADIOReducer = (state = initialState, action) => {
  if (action.type.includes("RADIO") && action.type.includes("REQUEST")) return { ...state, loading: true }
  else if (action.type.includes("RADIO") && action.type.includes("FAILED")) return { ...state, err: action.err, loading: false }
  else {
    switch (action.type) {

      case 'RADIO_SUCCESS_USER':
        return {
          ...state,
          Radios_User: action.data,
          loading: false
        }
      // case 'RADIO_SUCCESS_DOCTOR':
      //   return {
      //     ...state,
      //     Radios_Doctor: action.data,
      //     loading: false
      //   }
      // case 'RADIO_SUCCESS_REGISTRAR':
      //   return {
      //     ...state,
      //     Radios_Registrar: action.data,
      //     loading: false
      //   }
      case 'RADIO_SUCCESS_PATIENT':
        return {
          ...state,
          Patient_Orders: action.data,
          loading: false
        }
      case 'RADIO_TYPES_SUCCESS':
        return {
          ...state,
          Radios: action.data
        }
      case 'RADIO_ORDER_ADD_SUCCESS':
        return {
          ...state,
          loading: false
        }
      case 'RADIO_ORDER_DELETE_SUCCESS':
        return {
          ...state,
          loading: false
        }
      default:
        return { ...state }
    }
  }
}

export default RADIOReducer
