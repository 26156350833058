// **  Initial State
const initialState = {
    // Labs_Doctor: [],
    // Labs_Registrar: [],
    Labs_User: [],
    Patient_Orders: [],
    Labs: [],
    LabFd_Results: [],
    LabFd_Accepted:[],
    LabFd_NotAccepted:[],
    err : null,
    loading: false
  }
  
  const labReducer = (state = initialState, action) => {
    if (action.type.includes("LAB") && action.type.includes("REQUEST")) return {...state, loading: true}
    else if (action.type.includes("LAB") && action.type.includes("FAILED")) return {...state, err: action.err, loading: false}
    else {
      switch (action.type) {
        case 'LAB_SUCCESS_USER':
              return {
                ...state,
                Labs_User: action.data,
                loading: false
              }
        // case 'LAB_SUCCESS_DOCTOR':
        //       return {
        //         ...state,
        //         Labs_Doctor: action.data,
        //         loading: false
        //       }
        // case 'LAB_SUCCESS_REGISTRAR':
        //       return {
        //         ...state,
        //         Labs_Registrar: action.data,
        //         loading: false
        //       }
        case 'LAB_SUCCESS_PATIENT':
              return {
                    ...state,
                    Patient_Orders: action.data,
                    loading: false
                  }
        case 'LAB_TYPES_SUCCESS':
                return {
                    ...state,
                    Labs: action.data,
                    loading: false
                  }
        case 'LAB_PAGINATE_TYPES_SUCCESS':
                return {
                    ...state,
                    Labs: action.data
                  }
        case 'LAB_ORDER_ADD_SUCCESS':
                return {
                    ...state,
                    loading: false
                  }
        case 'LAB_ORDER_DELETE_SUCCESS':
                return {
                    ...state,
                    loading: false
                  }
        case 'LABFD_ORDER_ACCEPTED_SUCCESS':
                return {
                    ...state,
                    LabFd_Accepted: action.data,
                    loading: false
                  }
        case 'LABFD_ORDER_NOTACCEPTED_SUCCESS':
                return {
                    ...state,
                    LabFd_NotAccepted: action.data,
                    loading: false
                  }
        case 'LABFD_ORDER_RESULTS_SUCCESS':
                return {
                    ...state,
                    LabFd_Results: action.data,
                    loading: false
                  }
        case 'LABFD_ACCEPT_ORDER_SUCCESS':
                return {
                    ...state,
                    loading: false
                  }
        case 'LABFD_UPLOAD_SUCCESS':
                return {
                    ...state,
                    loading: false
                  }                     
        default:
          return {...state}
      }
    }
  }
  
  export default labReducer
  