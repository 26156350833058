const initialState = {
    loading: false,
    err: null,
    Planned_Interventions: [],
    Performed_Interventions: [],
    Interventions: []
}

const InterventionReducer = (state = initialState, action) => {
    if (action.type.includes("INTERVENTION") && action.type.includes("REQUEST")) return {...state, loading: true}
    else if (action.type.includes("INTERVENTION") && action.type.includes("FAILED")) return {...state, err: action.err, loading: false}
    else {
        switch (action.type) {
            case "PATIENT_INTERVENTION_SUCCESS":
                return {...state, loading: false, Planned_Interventions: action.planned, Performed_Interventions: action.performed}
            case "ALL_INTERVENTION_SUCCESS":
                return {...state, Interventions: action.data}
            case "INTERVENTION_ADD_SUCCESS":
                return {...state, loading: false}
            case "INTERVENTION_PERFORM_SUCCESS":
                return {...state, loading: false}             
            default:
                return {...state}
        }
    }
}

export default InterventionReducer