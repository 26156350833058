const initialState = {
    loading: false,
    Patient_Data: [],
    err: null
}

const FamilyHistoryReducer = (state = initialState, action) => {
    if (action.type.includes("FH") && action.type.includes("REQUEST")) return {...state, loading: true}
    else if (action.type.includes("FH") && action.type.includes("FAILED")) return {...state, err: action.err, loading: false}
    else {
        switch (action.type) {
            case "FH_PATIENT_SUCCESS":
                return {...state, loading: false, Patient_Data: action.data}
            case "FH_ADD_SUCCESS":
                return {...state, loading: false}        
            default:
                return {...state}    
        }
    }
}

export default FamilyHistoryReducer