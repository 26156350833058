const initialState = {
    loading: false,
    Patients_Allerges : [],
    Allerges: [],
    err: null
}

const allergyReducer = (state = initialState, action) => {
    if (action.type.includes("ALLERGES") && action.type.includes("REQUEST")) return {...state, loading: true}
    else if (action.type.includes("ALLERGES") && action.type.includes("FAILED")) return {...state, err: action.err, loading: false}
    else {
        switch (action.type) {
            case "GET_PATIENT_ALLERGES_SUCCESS":
                return {...state, Patients_Allerges: action.data, loading: false}
            case 'ALLERGES_TYPES_SUCCESS':
                return {
                    ...state,
                    Allerges: action.data
                    }
            case 'ALLERGES_PATIENT_ADD_SUCCESS':
                return {
                    ...state,
                    loading: false
                    }
            default:
                return {...state}
        }
    }

}

export default allergyReducer