const initialState = {
  loading: false,
  Patient_Data: [],
  Nurse_Data: [],
  err: null
}

const VitalReducer = (state = initialState, action) => {
  if (action.type.includes("VITAL") && action.type.includes("REQUEST")) return {...state, loading: true}
  else if (action.type.includes("VITAL") && action.type.includes("FAILED")) return {...state, err: action.err, loading: false}
  else {
      switch (action.type) {
          case "VITAL_PATIENT_SUCCESS":
              return {...state, loading: false, Patient_Data: action.data}
          case "VITAL_NURSE_PATIENT_SUCCESS":
              return {...state, loading: false, Nurse_Data: action.data}
          case "VITAL_ADD_SUCCESS":
              return {...state, loading: false}     
          case "VITAL_BULK_ADD_SUCCESS":
              return {...state, loading: false}    
          default:
              return {...state}    
      }
  }
}

export default VitalReducer