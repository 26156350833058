const initialState = {
    err: null,
    loading: false,
    appoints: [],
    filter_appoints: [],
    selectedCalendars: ['prev', 'current', 'coming'],
    selectedEvent: {}
}

const AppointmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_DOCTOR_APPOINTS_REQUEST" :
            return {...state, loading: true}
        case "GET_DOCTOR_APPOINTS_SUCCESS" :
            return {...state, loading: false, appoints: action.data, filter_appoints: action.data}
        case "GET_DOCTOR_APPOINTS_FAILED" :
            return {...state, loading: false, err: action.err}
        case "SELECT_EVENT":
            return {...state, selectedEvent: action.event}
        case "FILTER_APPOINTS":
            return {...state, loading: false, filter_appoints: action.data}    
        case 'UPDATE_FILTERS':
            // ** Updates Filters based on action filter
            const filterIndex = state.selectedCalendars.findIndex(i => i === action.filter)
            if (state.selectedCalendars.includes(action.filter)) {
                state.selectedCalendars.splice(filterIndex, 1)
            } else {
                state.selectedCalendars.push(action.filter)
            }
            if (state.selectedCalendars.length === 0) {
                state.filter_appoints.length = 0
            }
            return { ...state }
        case 'UPDATE_ALL_FILTERS':
            // ** Updates All Filters based on action value
            const value = action.value
            let selected = []
            if (value === true) {
                selected = ['prev', 'current', 'coming']
            } else {
                selected = []
            }
            return { ...state, selectedCalendars: selected }
        case "ADD_APPOINTMENT_REQUEST" :
            return {...state, loading: true}
        case "ADD_APPOINTMENT_SUCCESS" :
            return {...state, loading: false}
        case "ADD_APPOINTMENT_FAILED" :
            return {...state, loading: false, err: action.err}
        case "UPDATE_APPOINTMENT_REQUEST" :
            return {...state, loading: true}
        case "UPDATE_APPOINTMENT_SUCCESS" :
            return {...state, loading: false}
        case "UPDATE_APPOINTMENT_FAILED" :
            return {...state, loading: false, err: action.err}  
        case "DELETE_APPOINTMENT_REQUEST" :
            return {...state, loading: true}
        case "DELETE_APPOINTMENT_SUCCESS" :
            return {...state, loading: false}
        case "DELETE_APPOINTMENT_FAILED" :
            return {...state, loading: false, err: action.err}        
        default :
            return state            
    }
}

export default AppointmentReducer