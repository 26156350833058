// **  Initial State
const initialState = {
  userData: null,
  err : null,
  user_terms_id: null,
  forgeted:false,
  checkCode:false,
  loading: false,
  checker: null
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_REQUEST':
      return {
        ...state,
        loading: true
      }
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        userData: action.data,
        loading: false
      }
    case 'LOGIN_FAILED':
        return {
          ...state,
          err: action.err,
          loading: false
        }
    case 'TERMS_REQUEST':
        return {
          ...state,
          loading: true
        }
    case 'TERMS_SUCCESS':
        return {
          ...state,
          loading: false
        }
    case 'TERMS_FAILED':
        return {
          ...state,
          user_terms_id: action.data,
          loading: false
        }
    case 'LOGOUT':
      const obj = { ...action }
      delete obj.type
      return { ...state, userData: null, ...obj }
    case 'FORGET_REQUEST':
      return {
        ...state,
        loading: true
      }
    case 'FORGET_SUCCESS':
      return {
        ...state,
        forgeted: action.data,
        loading: false
      }
    case 'FORGET_FAILED':
        return {
          ...state,
          err: action.err,
          loading: false
        }
    case 'CLEAR_FORGET':
      return {
        ...state,
        forgeted: false
      }    
    case 'CHECK_CODE_REQUEST':
      return {
        ...state,
        loading: true
      }
    case 'CHECK_CODE_SUCCESS':
      return {
        ...state,
        checkCode: action.data,
        loading: false
      }
    case 'CHECK_CODE_FAILED':
        return {
          ...state,
          err: action.err,
          loading: false
        }
    case 'CHANGE_PASSWORD_REQUEST':
      return {
        ...state,
        loading: true
      }
    case 'CHANGE_PASSWORD_SUCCESS':
      return {
        ...state,
        loading: false
      }
    case 'CHANGE_PASSWORD_FAILED':
        return {
          ...state,
          err: action.err,
          loading: false
        }
    case 'CHANGE_TRIAL_PASSWORD_REQUEST':
      return {
        ...state,
        loading: true
      }
    case 'CHANGE_TRIAL_PASSWORD_SUCCESS':
      return {
        ...state,
        loading: false
      }
    case 'CHANGE_TRIAL_PASSWORD_FAILED':
        return {
          ...state,
          err: action.err,
          loading: false
        }       
    case "VERIFY_TRIAL_PASSWORD_REQUEST":
      return {
        ...state,
        loading: true
      }
    case "VERIFY_TRIAL_PASSWORD_SUCCESS":
      return {
        ...state,
        checker: action.data,
        loading: false
      } 
    case "VERIFY_TRIAL_PASSWORD_FAILED":
      return {
        ...state,
        err: action.err,
        loading: false
      }  
    case 'CHANGE_PHOTO_REQUEST':
      return {
        ...state,
        loading: true
      }
    case 'CHANGE_PHOTO_SUCCESS':
      return {
        ...state,
        userData:{...action.data},
        loading: false
      }
    case 'CHANGE_PHOTO_FAILED':
        return {
          ...state,
          err: action.err,
          loading: false
        }
    case 'ADD_GUEST_REQUEST':      
      return {
        ...state,
        loading: true
      }
    case 'ADD_GUEST_SUCCESS':      
      return {
        ...state,
        loading: false
      }  
    case 'ADD_GUEST_FAILED':      
      return {
        ...state,
        loading: false
      }              
    default:
      return state
  }
}

export default authReducer
