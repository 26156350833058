import problems from './problems'
import allerges from './allerges'
import visits from './visits'
import surgeries from './surgeries'
import interventions from './interventions'
import familyHistory from './familyHistory'
import chronic from './chronic'
import vaccination from './vaccination'
import private_files from './private_files'
import vitals from './vitals'

export const baseReducer = {
    problems,
    allerges,
    visits,
    surgeries,
    interventions,
    familyHistory,
    chronic,
    vaccination,
    private_files,
    vitals
}