// **  Initial State
const initialState = {
    Resolved_Problems: [],
    InResolved_Problems: [],
    Diagnoses: [],
    err : null,
    loading: false
  }
  
const problemsReducer = (state = initialState, action) => {
    if (action.type.includes("PROBLEM") && action.type.includes("REQUEST")) return {...state, loading: true}
    else if (action.type.includes("PROBLEM") && action.type.includes("FAILED")) return {...state, err: action.err, loading: false}
    else {
        switch (action.type) {
            case "PATIENT_PROBLEM_SUCCESS":
                return {
                    ...state,
                    Resolved_Problems: action.resolved,
                    InResolved_Problems: action.inresolved,
                    loading: false
                }    
            case "PATIENT_RESOLVED_PROBLEM_SUCCESS":
                return {
                    ...state,
                    Resolved_Problems: action.data,
                    loading: false
                }    
                // ** Un Resolved
            case "PATIENT_INRESOLVED_PROBLEM_SUCCESS":
                return {
                    ...state,
                    InResolved_Problems: action.data,
                    loading: false
                }    
            case "RESOLVE_PATIENT_PROBLEM_SUCCESS":
                return {
                    ...state,
                    loading: false
                }    
            case "ALL_DIAGNOSE_SUCCESS":
                return {
                    ...state,
                    Diagnoses: action.data
                } 
            default:
                return state   
        }
    }

}

export default problemsReducer