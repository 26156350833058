// **  Initial State
const initialState = {
  diagnoses_recommends:[],
  interventions_recommends:[],
  surgeries_recommends:[],
  medications_recommends:[],
  radios_recommends:[],
  labs_recommends:[],
  pathologies_recommends:[],
  allergies_recommends:[],
  err: null,
  loading: false
}

const RecommendationsReducer = (state = initialState, action) => {
  if (action.type.includes("RECOMMENDATIONS") && action.type.includes("REQUEST")) return { ...state, loading: true }
  else if (action.type.includes("RECOMMENDATIONS") && action.type.includes("FAILED")) return { ...state, err: action.err, loading: false }
  else {
    switch (action.type) {
      case 'RECOMMENDATIONS_DIAGNOSES_SUCCESS':
        return {
          ...state,
          diagnoses_recommends: action.data,
          loading: false
        }
      case 'RECOMMENDATIONS_ALLERGIES_SUCCESS':
        return {
          ...state,
          allergies_recommends: action.data,
          loading: false
        }
      case 'RECOMMENDATIONS_MEDICATIONS_SUCCESS':
        return {
          ...state,
          medications_recommends: action.data,
          loading: false
        }
      case 'RECOMMENDATIONS_SURGERIES_SUCCESS':
        return {
          ...state,
          surgeries_recommends: action.data,
          loading: false
        }
      case 'RECOMMENDATIONS_LABS_SUCCESS':
        return {
          ...state,
          labs_recommends: action.data,
          loading: false
        }
      case 'RECOMMENDATIONS_RADIOS_SUCCESS':
        return {
          ...state,
          radios_recommends: action.data,
          loading: false
        }
      case 'RECOMMENDATIONS_PATHOS_SUCCESS':
        return {
          ...state,
          pathologies_recommends: action.data,
          loading: false
        }
      case 'RECOMMENDATIONS_INTERVENTIONS_SUCCESS':
        return {
          ...state,
          interventions_recommends: action.data,
          loading: false
        }
      default:
        return { ...state }
    }
  }
}

export default RecommendationsReducer
