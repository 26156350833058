const initialState = {
    loading: false,
    Patient_Data: [],
    Chronics: [],
    err: null
}

const ChronicReducer = (state = initialState, action) => {
    if (action.type.includes("CHRONIC") && action.type.includes("REQUEST")) return {...state, loading: true}
    else if (action.type.includes("CHRONIC") && action.type.includes("FAILED")) return {...state, err: action.err, loading: false}
    else {
        switch (action.type) {
            case "CHRONIC_PATIENT_SUCCESS":
                return {...state, loading: false, Patient_Data: action.data}
            case 'CHRONIC_TYPES_SUCCESS':
                    return {
                        ...state,
                        Chronics: action.data
                        }
            case "CHRONIC_ADD_SUCCESS":
                return {...state, loading: false}        
            default:
                return {...state}    
        }
    }
}

export default ChronicReducer