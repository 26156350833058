// ** Redux, Thunk & Root Reducer Imports
import thunk from 'redux-thunk'
import createDebounce from 'redux-debounced'
import rootReducer from '../reducers/rootReducer'
import { createStore, applyMiddleware, compose } from 'redux'
import Uppy from '@uppy/core'
import ReduxStore from '@uppy/store-redux'
import {User} from '@src/Helper'
// ** init middleware
const middleware = [thunk, createDebounce()]

// ** Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// ** Initial State

const initialState = {
  auth : { userData : User.userData }
}

// ** Create store
const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middleware)))

const uppy = new Uppy({
    store: ReduxStore({
      store
    })
  })

export { store }
