// **  Initial State
const initialState = {
    Patients: [],
    err : null,
    loading: false
  }
  
  const emrReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'EMR_REQUEST':
        return {
          ...state,
          loading: true
        }
        case 'EMR_SUCCESS':
            return {
              ...state,
              Patients: action.data,
              loading: false
            }
      case 'EMR_FAILED':
          return {
            ...state,
            err: action.err,
            loading: false


          }
      default:
        return {...state}
    }
  }
  
  export default emrReducer
  