// **  Initial State
const initialState = {
    RadioFd_Results: [],
    RadioFd_Accepted:[],
    RadioFd_NotAccepted:[],
    err : null,
    loading: false
  }
  
  const radioReducer = (state = initialState, action) => {
    if (action.type.includes("URADIOFD") && action.type.includes("REQUEST")) return {...state, loading: true}
    else if (action.type.includes("URADIOFD") && action.type.includes("FAILED")) return {...state, err: action.err, loading: false}
    else {
      switch (action.type) {
        case 'URADIOFD_ORDER_ACCEPTED_SUCCESS':
                return {
                    ...state,
                    RADIOFd_Accepted: action.data,
                    loading: false
                  }
        case 'URADIOFD_ORDER_NOTACCEPTED_SUCCESS':
                return {
                    ...state,
                    RADIOFd_NotAccepted: action.data,
                    loading: false
                  }
        case 'URADIOFD_ORDER_RESULTS_SUCCESS':
                return {
                    ...state,
                    RADIOFd_Results: action.data,
                    loading: false
                  }
        case 'URADIOFD_ACCEPT_ORDER_SUCCESS':
                return {
                    ...state,
                    loading: false
                  }  
        case "URADIOFD_UPDATE_SUCCESS":
                return {
                  ...state,
                  loading: false
                }                    
        default:
          return {...state}
      }
    }
  }
  
  export default radioReducer
  