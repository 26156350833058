const initialState = {
    loading: false,
    patients_online: [],
    patients_online_filter: [],
    items_online: 1,
    patients_oncard: [],
    patients_oncard_filter: [],
    items_oncard: 1,
    files: [],
    fileData: null,
    err: null
}

const vendorReducer = (state = initialState, action) => {
    if (action.type.includes("VENDOR") && action.type.includes("REQUEST")) return {...state, loading: true}
    else if (action.type.includes("VENDOR") && action.type.includes("FAILED")) return {...state, err: action.err, loading: false}
    else {
        switch (action.type) {
            case "GET_VENDOR_ONLINE_PATIENTS_SUCCESS":
                return {...state, loading: false, patients_online: action.data, items_online: action.items}
            case "GET_VENDOR_ONCARD_PATIENTS_SUCCESS":
                return {...state, loading: false, patients_oncard: action.data, items_oncard: action.items}  
            case "SEARCH_VENDOR_PATIENTS_SUCCESS":
                return {...state, 
                    loading: false, 
                    ...(action.online === 1 ? {
                    patients_online_filter: action.data,
                    items_online: action.items
                } : {
                    patients_oncard_filter: action.data,
                    items_oncard: action.items
                })
            }
            case "GET_VENDOR_FILES_SUCCESS":
                return {...state, loading: false, files: action.data}
            case "GET_VENDOR_FILE_SUCCESS":
                return {...state, loading: false, fileData: action.data}
            default:
                return {...state}
        }
    }
}

export default vendorReducer
