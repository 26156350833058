const initialState = {
    err: null,
    loading: false,
    insert_loading: false,
    verify: null,
    verify_pin: null,
    qr_verify: null,
    inserted: null,
    ptDetails: null
}

const PatientVerfiyReducer = (state = initialState, action) => {
    switch (action.type) {
        case "PATIENT_VERIFY_REQUEST":
            return {...state, loading: true}
        case "PATIENT_VERIFY_SUCCESS":
            return {...state, verify: action.data, loading: false}    
        case "PATIENT_VERIFY_FAILED":
            return {...state, err: action.err, loading: false}
        case "PATIENT_VERIFY_PIN_REQUEST":
            return {...state, loading: true}
        case "PATIENT_VERIFY_PIN_SUCCESS":
            return {...state, verify_pin: action.data, loading: false}    
        case "PATIENT_VERIFY_PIN_FAILED":
            return {...state, err: action.err, loading: false}
        case "PATIENT_VERIFY_QR_REQUEST":
            return {...state, loading: true}
        case "PATIENT_VERIFY_QR_SUCCESS":
            return {...state, verify: action.data, loading: false}    
        case "PATIENT_VERIFY_QR_FAILED":
            return {...state, err: action.err, loading: false}
        case "PATIENT_VERIFY_ENCODED_REQUEST":
            return {...state, loading: true}
        case "PATIENT_VERIFY_ENCODED_SUCCESS":
            return {...state, verify: action.data, loading: false}    
        case "PATIENT_VERIFY_ENCODED_FAILED":
            return {...state, err: action.err, loading: false}
        case "PATIENT_DETAILS_REQUEST":
            return {...state, loading: true}
        case "PATIENT_DETAILS_SUCCESS":
            return {...state, ptDetails: action.data, loading: false}    
        case "PATIENT_DETAILS_FAILED":
            return {...state, err: action.err, loading: false} 
        case "PATIENT_INFO_REQUEST":
            return {...state, insert_loading: true}
        case "PATIENT_INFO_SUCCESS":
            return {...state, inserted: action.data, insert_loading: false}    
        case "PATIENT_INFO_FAILED":
            return {...state, err: action.err, insert_loading: false}    
        case "PATIENT_INFO_CLEAR":
            return {...state, verify: null}    
        default:
            return {...state}

    }
}

export default PatientVerfiyReducer