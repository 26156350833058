const initialState = {
    loading: false,
    Patient_Data: [],
    Vaccinations: [],
    err: null
}

const VaccinationReducer = (state = initialState, action) => {
    if (action.type.includes("VACCINATION") && action.type.includes("REQUEST")) return {...state, loading: true}
    else if (action.type.includes("VACCINATION") && action.type.includes("FAILED")) return {...state, err: action.err, loading: false}
    else {
        switch (action.type) {
            case "VACCINATION_PATIENT_SUCCESS":
                return {...state, loading: false, Patient_Data: action.data}
            case 'VACCINATION_TYPES_SUCCESS':
                    return {
                        ...state,
                        Vaccinations: action.data
                        }
            case "VACCINATION_ADD_SUCCESS":
                return {...state, loading: false}       
            default:
                return {...state}    
        }
    }
}

export default VaccinationReducer