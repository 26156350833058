const initialState = {
    loading_sms: false,
      err: null,
      sms_code: null,
      send_counter:0
  }
  
  
  const SMSReducer = (state = initialState, action) => {
      if (action.type.includes("SMS") && action.type.includes("REQUEST")) return {...state, loading_sms: true}
      else if (action.type.includes("SMS") && action.type.includes("FAILED")) return {...state, err: action.err, loading_sms: false}
      else {
          switch (action.type) {
              case "SMS_SUCCESS":
                return {
                  ...state,
                  sms_code: action.data.code,
                  loading_sms: false,
                  send_counter: state.send_counter + 1
                }  
              default:
                  return {...state}
          }
      }
    }
    
    export default SMSReducer
    