const initialState = {
  loading: false,
  err: null,
  openPreview: false,
  checkExist: null
}

const OtherHealthReducer = (state = initialState, action) => {
  if (action.type.includes("OTHER_HEALTH") && action.type.includes("REQUEST")) return { ...state, loading: true }
  else if (action.type.includes("OTHER_HEALTH") && action.type.includes("FAILED")) return { ...state, err: action.err, loading: false }
  else {
    switch (action.type) {

      case "OTHER_HEALTH_TRIAL_SUCCESS":
        return {
          ...state,
          loading: false,
          openPreview: true
        }
        case "OTHER_HEALTH_CHECK_SUCCESS":
          return { ...state, checkExist: action.data, loading: false }
      default:
        return { ...state }
    }
  }
}

export default OtherHealthReducer
